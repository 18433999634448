<template>
  <app-form-field v-bind="fieldBinding">
    <template #default="{ inputClass }">
      <input
        ref="input"
        autocapitalize="off"
        :class="inputClass"
        :placeholder="placeholder"
        :readonly="isReadonly"
        :type="type"
        :value="value"
        v-on="listeners"
        @keydown.enter.prevent
      />
    </template>

    <template v-if="tooltip || $slots.append" #append>
      <slot name="append">
        <div
          v-if="tooltip"
          v-tooltip="tooltip"
          class="text-subtle block relative before:content-[''] before:absolute before:-inset-2"
        >
          <app-icon icon="ph:info" :size="18" />
        </div>
      </slot>
    </template>
  </app-form-field>
</template>

<script lang="ts" setup>
import {
  appFormInputProperties,
  useAppFormInput,
} from "../composables/form-input.hook";

const properties = defineProps({ ...appFormInputProperties });

const { value, listeners, fieldBinding, isReadonly } =
  useAppFormInput(properties);

if (properties.autofocus && process.client) {
  const inputElement = templateRef<HTMLInputElement>("input");

  onMounted(async () => {
    /*
    Ensure the forced focus does not break any transition (ie: focused input in a dialog)
     */
    await nextTick();
    inputElement.value.focus();
  });
}
</script>
